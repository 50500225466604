import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Landing from "./Landing";

function App() {
  return (
    <div>
      <Landing />
    </div>
  );
}

export default App;
